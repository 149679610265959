<template>
  <div class="row">
    <div class="col-12 col-sm-5 col-md-5">
      <div class="card">
        <div class="card-body">
          <canvas id="c"></canvas>
          <!-- <img
            src="@/assets/images/mycars2.png"
            id="my-image"
            width="300"
            height="200"
          /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  mounted() {
  //   var canvas = new fabric.Canvas("c");
  //   canvas.add(new fabric.Circle({ radius: 30, fill: '#f55', top: 100, left: 100 }));
  //   canvas.add(new fabric.Circle({ radius: 30, fill: '#f55', top: 100, left: 200 }));
  //   // canvas.setBackgroundImage('@/assets/images/mycars2.png', canvas.renderAll.bind(canvas));

  //   canvas.on('mouse:down', function(options) {
  // if (options.target) {
  //   // console.log('an object was clicked! ', options.target.type);
  // }
// });
  },
};
</script>
